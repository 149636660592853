<template>
  <main>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      title="Назад"
      @back="() => $router.go(-1)"
    />
    <a-form-model style="padding: 20px" layout="vertical" :model="news">
      <a-form-model-item label="Заголовок">
        <a-input v-model="news.name" placeholder="Заголовок..." />
      </a-form-model-item>
      <a-form-model-item label="Содержание*">
        <vue-editor
          v-if="news.text"
          :editor-toolbar="customToolbar"
          v-model="news.text"
          style="background-color: white"
        />
      </a-form-model-item>
      <a-form-model-item
        label="Картинка"
        style="background-color: white; padding: 10px"
      >
        <div style="display: flex; margin-bottom: 5px">
          <span style="margin-right: 5px">Загрузить новую:</span>
          <input
            type="file"
            id="img"
            name="img"
            @change="handleChange"
            accept="image/*"
          />
        </div>
        <div id="preview" style="max-height: 60%; max-width: 60%">
          <img
            v-if="coverChanged == false"
            :src="news.cover"
            style="max-width: 100%; max-height: 100%"
          />
          <img
            v-if="coverChanged == true"
            :src="item.imageUrl"
            style="max-width: 100%; max-height: 100%"
          />
        </div>
      </a-form-model-item>
      <span style="color: red">{{ errorMessage }}</span>
      <a-form-model-item>
        <a-button type="primary" @click="editNews"> Сохранить </a-button>
      </a-form-model-item>
    </a-form-model>
  </main>
</template>

<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";

export default {
  name: "NewsEdit",
  components: { VueEditor },
  props: ["newsId"],
  data: function () {
    return {
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
        ["clean"],
      ],
      loading: false,
      imageUrl: "",
      news: {
        name: "",
        text: "",
        cover: "",
        created: null,
        // news_status: this.newsStatus,
      },
      original: {
        name: "",
        text: "",
      },
      item: {
        imageUrl: "",
      },
      image: "",
      file: null,
      news_id: this.newsId,
      coverChanged: false,
      previewVisible: true,
      previewImage: "",
      fileList: [],
      errorMessage: null,
    };
  },
  created() {
    this.getOneNews();
  },
  methods: {
    handleChange(e) {
      this.coverChanged = true;
      const file = e.target.files[0];
      this.image = file;
      this.item.imageUrl = URL.createObjectURL(file);
      console.log(this.item.imageUrl);
      const reader = new FileReader();
      reader.readAsDataURL(this.image);
      reader.onload = (e) => {
        this.supaimage = e.target.result;
      };
      this.previewVisible = false;
    },
    getOneNews: function () {
      axios
        .get(`/cp/news/${this.newsId}/`, {
          params: {
            fmt: "html",
          },
        })
        .then((response) => {
          this.news.name = response.data.data.name;
          this.original.name = response.data.data.body;
          this.news.cover = response.data.data.cover.replace(
            "http://188.225.77.74:5000/",
            "https://api.skinallergic.ru/"
          );
          this.news.created = response.data.data.created;
          this.news.text = response.data.data.body.replace(/\n/gim, "");
          this.original.text = response.data.data.body.replace(/\n/gim, "");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editNews: function () {
      let form_data = new FormData();
      if (this.news.name != this.original.name) {
        form_data.append("name", this.news.name);
      }
      if (this.news.text != this.original.text) {
        form_data.append("body", this.news.text);
      }
      if (this.coverChanged) {
        form_data.append("cover", this.image);
      }
      console.log(form_data.getAll("body"));
      axios({
        method: "PATCH",
        url: `/cp/news/${this.news_id}/`,
        data: form_data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          console.log(response);
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
